export const tableFieldDealerCalculation = [
  { text: 'langkey.article-type', value: 'articleType' },
  { text: 'langkey.article-group', value: 'articleGroup' },
  { text: 'langkey.tyre-retreaded', value: 'tyreRetreaded' },
  { text: 'langkey.price-from', value: 'priceFrom' },
  { text: 'langkey.price-to', value: 'priceTo' },
  { text: 'langkey.diameter-from', value: 'diameterFrom' },
  { text: 'langkey.diameter-to', value: 'diameterTo' },
  { text: 'langkey.surcharge_euros', value: 'add' },
  { text: 'langkey.surcharge_percent', value: 'percent' },
  { text: 'langkey.action-dealer-calculation', value: 'action' },
];

export const articleTypeLabel = {
  'tyre': {
    label: 'langkey.tyre',
  },
  'wheel': {
    label: 'langkey.wheel',
  },
  'tpms_sensor': {
    label: 'langkey.dealer-calculation-tpms',
  },
};

export const articleGroupLabel = {
  'steel': {
    label: 'langkey.dealer-calculation-wheel-steel',
  },
  'alloy': {
    label: 'langkey.dealer-calculation-wheel-alloy',
  },
  'car': {
    label: 'langkey.dealer-calculation-tyre-car',
  },
  'bike': {
    label: 'langkey.dealer-calculation-tyre-bike',
  },
  'truck': {
    label: 'langkey.dealer-calculation-tyre-truck',
  },
  'lighttruck': {
    label: 'langkey.dealer-calculation-tyre-ligthtruck',
  },
  'sensor': {
    label: 'langkey.dealer-calculation-tpms-sensor',
  },
  'service kit': {
    label: 'langkey.dealer-calculation-tpms-service-kits',
  },
  'ventil kit': {
    label: 'langkey.dealer-calculation-tpms-valve-kits',
  },
};
