<template>
  <v-dialog
    v-model="showDialog"
    max-width="500"
    rounded="lg"
    @click:outside="hide"
  >
    <v-card class="base-dialog dealer-calculation-delete-dialog">
      <v-card-title
        class="headline"
      >
        <h2
          class="title-text"
        >
          {{ $t('langkey.modal-delete-dealer-calculation-header') }}
        </h2>
        <v-spacer />
        <v-btn
          class="button__close"
          small
          icon
          @click="hide"
        >
          <icon-close />
        </v-btn>
      </v-card-title>
      <v-card-text>
        <p
          v-html="$t('langkey.modal-delete-dealer-calculation-message')"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <template>
          <v-btn
            plain
            @click="hide"
          >
            {{ $t('langkey.cart-cancel-delete') }}
          </v-btn>
          <v-btn
            color="error"
            plain
            class="icon"
            @click="deleteCalculation"
          >
            {{ $t('langkey.cart-delete') }}
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      showDialog: false,
    };
  },
  methods: {
    show() {
      this.showDialog = true;
    },
    hide() {
      this.showDialog = false;
    },
    deleteCalculation() {
      this.$emit('delete-calculation');
      this.hide();
    },
  },
};
</script>
