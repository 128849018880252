var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"771","rounded":"lg"},on:{"click:outside":_vm.hide,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.hide.apply(null, arguments)}},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',{staticClass:"base-dialog dealer-calculation-form-dialog"},[_c('v-card-title',{staticClass:"headline"},[_c('h2',{staticClass:"title-text"},[_vm._v(" "+_vm._s(_vm.$t(_vm.title))+" ")]),_c('v-spacer'),_c('v-btn',{staticClass:"button__close",attrs:{"small":"","icon":""},on:{"click":_vm.hide}},[_c('icon-close')],1)],1),_c('v-card-text',[_c('div',{staticClass:"calculation-form"},[_c('table',[_c('tbody',[_c('tr',[_c('td'),_c('td',[(_vm.errorMessage)?_c('p',{staticClass:"error-message",domProps:{"innerHTML":_vm._s(_vm.errorMessage)}}):_vm._e()])]),_c('tr',[_c('td',[_c('span',{class:[
                    _vm.inputError.articleType ||
                      _vm.inputError.articleGroup ||
                      _vm.inputError.tyreRetreaded ?
                        'error-message' : ''
                  ]},[_vm._v(" "+_vm._s(_vm.$t('langkey.article-type'))+" ")])]),_c('td',[(_vm.inputError.articleType ||
                    _vm.inputError.articleGroup ||
                    _vm.inputError.tyreRetreaded)?_c('p',{staticClass:"error-message",domProps:{"innerHTML":_vm._s(_vm.inputErrorMessage.articleType)}}):_vm._e(),_c('article-type-dropdown',{attrs:{"calculation":_vm.calculation,"is-clear-article-type":_vm.isClearArticleType},on:{"select-article-type":_vm.selectArticleType}})],1)]),_c('tr',[_c('td',[_c('span',{class:[
                    _vm.inputError.priceFrom ?
                      'error-message' : ''
                  ]},[_vm._v(" "+_vm._s(_vm.$t('langkey.price-from'))+" ")])]),_c('td',[(_vm.inputError.priceFrom)?_c('p',{staticClass:"error-message",domProps:{"innerHTML":_vm._s(_vm.inputErrorMessage.priceFrom)}}):_vm._e(),_c('v-text-field',{attrs:{"solo":"","hide-details":"","type":"number"},model:{value:(_vm.calculation.priceFrom),callback:function ($$v) {_vm.$set(_vm.calculation, "priceFrom", $$v)},expression:"calculation.priceFrom"}})],1)]),_c('tr',[_c('td',[_c('span',{class:[
                    _vm.inputError.priceTo ?
                      'error-message' : ''
                  ]},[_vm._v(" "+_vm._s(_vm.$t('langkey.price-to'))+" ")])]),_c('td',[(_vm.inputError.priceTo)?_c('p',{staticClass:"error-message",domProps:{"innerHTML":_vm._s(_vm.inputErrorMessage.priceTo)}}):_vm._e(),_c('v-text-field',{attrs:{"solo":"","hide-details":"","type":"number"},model:{value:(_vm.calculation.priceTo),callback:function ($$v) {_vm.$set(_vm.calculation, "priceTo", $$v)},expression:"calculation.priceTo"}})],1)]),_c('tr',[_c('td',[_c('span',{class:[
                    _vm.inputError.diameterFrom ?
                      'error-message' : ''
                  ]},[_vm._v(" "+_vm._s(_vm.$t('langkey.diameter-from'))+" ")])]),_c('td',[(_vm.inputError.diameterFrom)?_c('p',{staticClass:"error-message",domProps:{"innerHTML":_vm._s(_vm.inputErrorMessage.diameterFrom)}}):_vm._e(),_c('v-text-field',{attrs:{"solo":"","hide-details":"","type":"number"},model:{value:(_vm.calculation.diameterFrom),callback:function ($$v) {_vm.$set(_vm.calculation, "diameterFrom", $$v)},expression:"calculation.diameterFrom"}})],1)]),_c('tr',[_c('td',[_c('span',{class:[
                    _vm.inputError.diameterTo ?
                      'error-message' : ''
                  ]},[_vm._v(" "+_vm._s(_vm.$t('langkey.diameter-to'))+" ")])]),_c('td',[(_vm.inputError.diameterTo)?_c('p',{staticClass:"error-message",domProps:{"innerHTML":_vm._s(_vm.inputErrorMessage.diameterTo)}}):_vm._e(),_c('v-text-field',{attrs:{"solo":"","hide-details":"","type":"number"},model:{value:(_vm.calculation.diameterTo),callback:function ($$v) {_vm.$set(_vm.calculation, "diameterTo", $$v)},expression:"calculation.diameterTo"}})],1)]),_c('tr',[_c('td',{attrs:{"colspan":"2"}},[_c('div',{staticClass:"calculation-note"},[_vm._v(" "+_vm._s(_vm.$t('langkey.note-surcharges'))+" ")])])]),_c('tr',[_c('td',[_c('span',{class:[
                    _vm.inputError.percent ?
                      'error-message' : ''
                  ]},[_vm._v(" "+_vm._s(_vm.$t('langkey.surcharge_in_percent'))+" ")])]),_c('td',[(_vm.inputError.percent)?_c('p',{staticClass:"error-message",domProps:{"innerHTML":_vm._s(_vm.inputErrorMessage.percent)}}):_vm._e(),_c('v-text-field',{attrs:{"solo":"","hide-details":"","type":"number"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('i',{staticClass:"append-icon"},[_vm._v(" % ")])]},proxy:true}]),model:{value:(_vm.calculation.percent),callback:function ($$v) {_vm.$set(_vm.calculation, "percent", $$v)},expression:"calculation.percent"}})],1)]),_c('tr',[_c('td',[_c('span',{class:[
                    _vm.inputError.add ?
                      'error-message' : ''
                  ]},[_vm._v(" "+_vm._s(_vm.$t('langkey.surcharge_in_euros'))+" ")])]),_c('td',[(_vm.inputError.add)?_c('p',{staticClass:"error-message",domProps:{"innerHTML":_vm._s(_vm.inputErrorMessage.add)}}):_vm._e(),_c('v-text-field',{attrs:{"solo":"","hide-details":"","type":"number"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('i',{staticClass:"append-icon"},[_vm._v(" € ")])]},proxy:true}]),model:{value:(_vm.calculation.add),callback:function ($$v) {_vm.$set(_vm.calculation, "add", $$v)},expression:"calculation.add"}})],1)])])])])]),_c('v-card-actions',[_c('v-spacer'),[_c('v-btn',{attrs:{"plain":""},on:{"click":_vm.cancelCalculation}},[_vm._v(" "+_vm._s(_vm.$t('langkey.decline'))+" ")]),_c('v-btn',{staticClass:"icon",attrs:{"color":"accent"},on:{"click":_vm.saveCalculation}},[_vm._v(" "+_vm._s(_vm.$t('langkey.save'))+" ")])]],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }