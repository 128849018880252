<template>
  <v-dialog
    v-model="showDialog"
    max-width="771"
    rounded="lg"
    @click:outside="hide"
    @keydown.esc="hide"
  >
    <v-card class="base-dialog dealer-calculation-form-dialog">
      <v-card-title
        class="headline"
      >
        <h2
          class="title-text"
        >
          {{ $t(title) }}
        </h2>
        <v-spacer />
        <v-btn
          class="button__close"
          small
          icon
          @click="hide"
        >
          <icon-close />
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div class="calculation-form">
          <table>
            <tbody>
              <tr>
                <td />
                <td>
                  <p
                    v-if="errorMessage"
                    class="error-message"
                    v-html="errorMessage"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <span
                    :class="[
                      inputError.articleType ||
                        inputError.articleGroup ||
                        inputError.tyreRetreaded ?
                          'error-message' : ''
                    ]"
                  >
                    {{ $t('langkey.article-type') }}
                  </span>
                </td>
                <td>
                  <p
                    v-if="inputError.articleType ||
                      inputError.articleGroup ||
                      inputError.tyreRetreaded"
                    class="error-message"
                    v-html="inputErrorMessage.articleType"
                  />
                  <article-type-dropdown
                    :calculation="calculation"
                    :is-clear-article-type="isClearArticleType"
                    @select-article-type="selectArticleType"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <span
                    :class="[
                      inputError.priceFrom ?
                        'error-message' : ''
                    ]"
                  >
                    {{ $t('langkey.price-from') }}
                  </span>
                </td>
                <td>
                  <p
                    v-if="inputError.priceFrom"
                    class="error-message"
                    v-html="inputErrorMessage.priceFrom"
                  />
                  <v-text-field
                    v-model="calculation.priceFrom"
                    solo
                    hide-details
                    type="number"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <span
                    :class="[
                      inputError.priceTo ?
                        'error-message' : ''
                    ]"
                  >
                    {{ $t('langkey.price-to') }}
                  </span>
                </td>
                <td>
                  <p
                    v-if="inputError.priceTo"
                    class="error-message"
                    v-html="inputErrorMessage.priceTo"
                  />
                  <v-text-field
                    v-model="calculation.priceTo"
                    solo
                    hide-details
                    type="number"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <span
                    :class="[
                      inputError.diameterFrom ?
                        'error-message' : ''
                    ]"
                  >
                    {{ $t('langkey.diameter-from') }}
                  </span>
                </td>
                <td>
                  <p
                    v-if="inputError.diameterFrom"
                    class="error-message"
                    v-html="inputErrorMessage.diameterFrom"
                  />
                  <v-text-field
                    v-model="calculation.diameterFrom"
                    solo
                    hide-details
                    type="number"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <span
                    :class="[
                      inputError.diameterTo ?
                        'error-message' : ''
                    ]"
                  >
                    {{ $t('langkey.diameter-to') }}
                  </span>
                </td>
                <td>
                  <p
                    v-if="inputError.diameterTo"
                    class="error-message"
                    v-html="inputErrorMessage.diameterTo"
                  />
                  <v-text-field
                    v-model="calculation.diameterTo"
                    solo
                    hide-details
                    type="number"
                  />
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <div class="calculation-note">
                    {{ $t('langkey.note-surcharges') }}
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <span
                    :class="[
                      inputError.percent ?
                        'error-message' : ''
                    ]"
                  >
                    {{ $t('langkey.surcharge_in_percent') }}
                  </span>
                </td>
                <td>
                  <p
                    v-if="inputError.percent"
                    class="error-message"
                    v-html="inputErrorMessage.percent"
                  />
                  <v-text-field
                    v-model="calculation.percent"
                    solo
                    hide-details
                    type="number"
                  >
                    <template #append>
                      <i class="append-icon">
                        %
                      </i>
                    </template>
                  </v-text-field>
                </td>
              </tr>
              <tr>
                <td>
                  <span
                    :class="[
                      inputError.add ?
                        'error-message' : ''
                    ]"
                  >
                    {{ $t('langkey.surcharge_in_euros') }}
                  </span>
                </td>
                <td>
                  <p
                    v-if="inputError.add"
                    class="error-message"
                    v-html="inputErrorMessage.add"
                  />
                  <v-text-field
                    v-model="calculation.add"
                    solo
                    hide-details
                    type="number"
                  >
                    <template #append>
                      <i class="append-icon">
                        €
                      </i>
                    </template>
                  </v-text-field>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <template>
          <v-btn
            plain
            @click="cancelCalculation"
          >
            {{ $t('langkey.decline') }}
          </v-btn>
          <v-btn
            color="accent"
            class="icon"
            @click="saveCalculation"
          >
            {{ $t('langkey.save') }}
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
@import "@/styles/components/dealer-calculation-form-dialog.scss";
</style>

<script>
import ArticleTypeDropdown from '@/components/ArticleTypeDropdown';
export default {
  components:{
    ArticleTypeDropdown,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    calculation: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showDialog: false,
      isClearArticleType: true,
      errorMessage: '',
      inputError: {
        articleType: false,
        articleGroup: false,
        tyreRetreaded: false,
        priceFrom: false,
        priceTo: false,
        diameterFrom: false,
        diameterTo: false,
        add: false,
        percent: false,
      },
      inputErrorMessage: {
        articleType: '',
        priceFrom: '',
        priceTo: '',
        diameterFrom: '',
        diameterTo: '',
        add: '',
        percent: '',
      },
    };
  },
  beforeDestroy() {
    this.$store.dispatch('dialog/setLoadingDialog', {
      status: false,
    });
  },
  methods: {
    show() {
      this.isClearArticleType = false;
      this.showDialog = true;
    },
    hide() {
      this.isClearArticleType = true;
      this.showDialog = false;
      this.errorMessage = '';
      Object.keys(this.inputError).forEach((key) => {
        this.inputError[key] = false;
      });
      Object.keys(this.inputErrorMessage).forEach((key) => {
        this.inputErrorMessage[key] = '';
      });
    },
    selectArticleType(selectedType) {
      this.calculation.articleType = selectedType.articleType;
      this.calculation.articleGroup = selectedType.articleGroup;
      this.calculation.tyreRetreaded = selectedType.retreaded;
    },
    cancelCalculation() {
      this.hide();
      this.$emit('clear-calculation');
    },
    async saveCalculation() {
      this.$store.dispatch('dialog/setLoadingDialog', {
        status: true,
      });
      this.errorMessage = '';
      Object.keys(this.inputError).forEach((key) => {
        this.inputError[key] = false;
      });
      Object.keys(this.inputErrorMessage).forEach((key) => {
        this.inputErrorMessage[key] = '';
      });
      if (!this.calculation.articleType) {
        this.inputError.articleType = true;
        this.inputErrorMessage.articleType = this.$t('langkey.missing-article-type');
        this.$store.dispatch('dialog/setLoadingDialog', {
          status: false,
        });
      } else if (this.calculation.id) {
        const id = this.calculation.id;
        const payload = this.getPayload();
        await this.updateCalculation(id, payload);
      } else {
        const payload = this.getPayload();
        await this.createCalculation(payload);
      }
    },
    async createCalculation(payload) {
      try {
        await this.$store.dispatch('dealerCalculation/createCalculation', payload);
        this.hide();
        this.$emit('save-calculation');
        this.$store.dispatch('dialog/setLoadingDialog', {
          status: false,
        });
        Object.keys(this.inputError).forEach((key) => {
          this.inputError[key] = false;
        });
      } catch (err) {
        if (err.response && err.response.status === 422) {
          const errorMessages = err.response.data;
          errorMessages.forEach((item) => {
            this.inputError[item.field] = true;
            this.inputErrorMessage[item.field] += `${item.error}<br/>`;
          });
        } else {
          this.errorMessage = 'langkey.save-calculation-error';
        }
        this.$store.dispatch('dialog/setLoadingDialog', {
          status: false,
        });
      }
    },
    async updateCalculation(id, payload) {
      try {
        await this.$store.dispatch('dealerCalculation/updateCalculation', {
          id,
          payload,
        });
        this.hide();
        this.$emit('save-calculation');
        this.$store.dispatch('dialog/setLoadingDialog', {
          status: false,
        });
        Object.keys(this.inputError).forEach((key) => {
          this.inputError[key] = false;
        });
      } catch (err) {
        if (err.response && err.response.status === 422) {
          const errorMessages = err.response.data;
          errorMessages.forEach((item) => {
            this.inputError[item.field] = true;
            this.inputErrorMessage[item.field] = item.error;
          });
        } else {
          this.errorMessage = 'langkey.save-calculation-error';
        }
        this.$store.dispatch('dialog/setLoadingDialog', {
          status: false,
        });
      }
    },
    getPayload() {
      let payload = {
        articleType: this.calculation.articleType || null,
        articleGroup: this.calculation.articleGroup || null,
        tyreRetreaded: this.calculation.tyreRetreaded,
        priceFrom: this.calculation.priceFrom !== undefined &&
          this.calculation.priceFrom !== null &&
          this.calculation.priceFrom !== '' ? Number(this.calculation.priceFrom) : null,
        priceTo: this.calculation.priceTo !== undefined &&
          this.calculation.priceTo !== null &&
          this.calculation.priceTo !== '' ? Number(this.calculation.priceTo) : null,
        diameterFrom: this.calculation.diameterFrom !== undefined &&
          this.calculation.diameterFrom !== null &&
          this.calculation.diameterFrom !== '' ? Number(this.calculation.diameterFrom) : null,
        diameterTo: this.calculation.diameterTo !== undefined &&
          this.calculation.diameterTo !== null &&
          this.calculation.diameterFrom !== null &&
          this.calculation.diameterFrom !== '' ? Number(this.calculation.diameterTo) : null,
        add: this.calculation.add !== undefined ? Number(this.calculation.add) : 0,
        percent: this.calculation.percent !== undefined ? Number(this.calculation.percent) : 0,
      };
      return payload;
    },
  },
};
</script>
