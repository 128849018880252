export const articleTypes = [
  {
    category: 'tyre',
    label: 'langkey.tyre',
    articles: [
      {
        label: 'langkey.dealer-calculation-tyre-car',
        value: {
          articleType: 'tyre',
          articleGroup: 'car',
          retreaded: false,
        },
      },
      {
        label: 'langkey.dealer-calculation-tyre-bike',
        value: {
          articleType: 'tyre',
          articleGroup: 'bike',
          retreaded: false,
        },
      },
      {
        label: 'langkey.dealer-calculation-tyre-truck',
        value: {
          articleType: 'tyre',
          articleGroup: 'truck',
          retreaded: false,
        },
      },
      {
        label: 'langkey.dealer-calculation-tyre-ligthtruck',
        value: {
          articleType: 'tyre',
          articleGroup: 'lightTruck',
          retreaded: false,
        },
      },
    ],
  },
  {
    category: 'tyreRetreaded',
    label: 'langkey.tyre-retreaded',
    articles: [
      {
        label: 'langkey.dealer-calculation-tyre-truck-retreaded',
        value: {
          articleType: 'tyre',
          articleGroup: 'truck',
          retreaded: true,
        },
      },
      {
        label: 'langkey.dealer-calculation-tyre-ligthtruck-retreaded',
        value: {
          articleType: 'tyre',
          articleGroup: 'lightTruck',
          retreaded: true,
        },
      },
    ],
  },
  {
    category: 'wheel',
    label: 'langkey.wheel',
    articles: [
      {
        label: 'langkey.dealer-calculation-wheel-alloy',
        value: {
          articleType: 'wheel',
          articleGroup: 'ALLOY',
          retreaded: false,
        },
      },
      {
        label: 'langkey.dealer-calculation-wheel-steel',
        value: {
          articleType: 'wheel',
          articleGroup: 'STEEL',
          retreaded: false,
        },
      },
    ],
  },
  {
    category: 'tpms_sensor',
    label: 'langkey.dealer-calculation-tpms',
    articles: [
      {
        label: 'langkey.dealer-calculation-tpms-sensor',
        value: {
          articleType: 'tpms_sensor',
          articleGroup: 'Sensor',
          retreaded: false,
        },
      },
      {
        label: 'langkey.dealer-calculation-tpms-service-kits',
        value: {
          articleType: 'tpms_sensor',
          articleGroup: 'Service Kit',
          retreaded: false,
        },
      },
      {
        label: 'langkey.dealer-calculation-tpms-valve-kits',
        value: {
          articleType: 'tpms_sensor',
          articleGroup: 'Ventil Kit',
          retreaded: false,
        },
      },
    ],
  },
];
