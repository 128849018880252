<template>
  <div>
    <v-menu
      v-model="isShowMenu"
      offset-y
      class="article-type__dropdown"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          depressed
          plain
          v-bind="attrs"
          class="article-type__dropdown-button"
          :class="[
            'dropdown',
            isShowMenu && 'show-menu'
          ]"
          v-on="on"
          @click="isShowMenu = true"
        >
          <span>{{ getSelectedArticleType }}</span>
          <div
            :class="[
              'icon-caret',
              isShowMenu && 'open'
            ]"
          >
            <icon-caret />
          </div>
        </v-btn>
      </template>
      <v-list class="article-type-dropdown___content">
        <v-list-group
          v-for="(articleType, index) in articleTypes"
          :key="`${index}`"
          class="article-group"
          no-action
          sub-group
          :value="true"
          disabled
        >
          <template v-slot:activator>
            <v-list-item-title class="aticle-group-title">
              {{ $t(articleType.label) }}
            </v-list-item-title>
          </template>
          <v-list-item
            v-for="(article, i) in articleType.articles"
            :key="i"
          >
            <v-list-item-title
              @click="selectArticleType(article)"
            >
              <span>{{ $t(article.label) }}</span>
            </v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-menu>
  </div>
</template>

<style lang="scss">
@import '@/styles/components/article-type-dropdown.scss';
</style>

<script>
import IconCaret from '@/components/icons/IconCaret';
import { articleTypes } from '@/configs/components/dealerCalculationForm';

export default {
  components: {
    IconCaret,
  },
  props: {
    calculation: {
      type: Object,
      default: () => {},
    },
    isClearArticleType: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isShowMenu: false,
      selectedArticleType: '-',
    };
  },
  computed: {
    articleTypes() {
      return articleTypes;
    },
    getSelectedArticleType() {
      return this.$t(this.selectedArticleType);
    },
  },
  watch: {
    isClearArticleType(val) {
      this.clearArticleType(val);
    },
  },
  created() {
    this.matchArticleType();
  },
  methods: {
    async selectArticleType(articleType) {
      this.isShowMenu = false;
      this.selectedArticleType = articleType.label;
      this.$emit('select-article-type', articleType.value);
    },
    matchArticleType() {
      let articleType = null;
      this.articleTypes.forEach((element) => {
        const tempArticle = element.articles.find((item) => {
          return (item.value.articleType === this.calculation.articleType
          && item.value.articleGroup === this.calculation.articleGroup
          && item.value.retreaded === this.calculation.tyreRetreaded);
        });
        if (tempArticle) {
          articleType = { ...tempArticle };
        }
      });
      if (articleType) {
        this.selectArticleType(articleType);
      }
    },
    clearArticleType(val) {
      this.selectedArticleType = (val) ? '-' : this.selectedArticleType;
      if (!val) {
        this.matchArticleType();
      }
    },
  },
};
</script>