<template>
  <section class="admin-dealer-calculation">
    <v-container class="custom-container">
      <div class="textbox">
        <h1>{{ $t('langkey.dealer-calculation') }}</h1>
        <p class="description">
          {{ $t('langkey.dealer-calculation-message') }}
        </p>
      </div>

      <v-card
        rounded="sm"
        class="dealer-calculation"
      >
        <v-data-table
          class="primary-style"
          :headers="tableFieldDealerCalculation"
          :items="calculations"
          :loading="loading"
          hide-default-footer
          disable-sort
          disable-pagination
        >
          <template
            v-for="(field, index) in tableFieldDealerCalculation"
            v-slot:[`header.${field.value}`]="{ header }"
          >
            <span :key="`${field.value}${index}`">{{ $t(header.text) }}</span>
          </template>
          <template v-slot:[`item.articleType`]="{ item }">
            {{ $t(articleTypeLabel[item.articleType].label) }}
          </template>
          <template v-slot:[`item.articleGroup`]="{ item }">
            {{ getArticleGroupLabel(item.articleGroup) }}
          </template>
          <template v-slot:[`item.tyreRetreaded`]="{ item }">
            <icon-check-plain
              v-if="item.tyreRetreaded"
              :size="40"
            />
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              outlined
              class="update-calculation__button"
              @click="openCalculationFormDialog(item)"
            >
              {{ $t('langkey.patch-dealer-calculation') }}
            </v-btn>
            <v-btn
              color="accent"
              class="delete-calculation__button"
              @click="openDeleteCalculationDialog(item.id)"
            >
              {{ $t('langkey.delete-dealer-calculation') }}
            </v-btn>
          </template>
        </v-data-table>
        <v-card-text class="card__content">
          <div class="actions__button right">
            <v-btn
              outlined
              class="new-calculation__button"
              @click="openCalculationFormDialog()"
            >
              {{ $t('langkey.new-calculation') }}
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-container>
    <dealer-calculation-form-dialog
      ref="dealerCalculationFormDialog"
      :title="calculationFormTitle"
      :calculation="calculation"
      @save-calculation="saveCalculation"
      @clear-calculation="clearCalculation"
    />
    <dealer-calculation-delete-dialog
      ref="dealerCalculationDeleteDialog"
      @delete-calculation="deleteCalculation"
    />
  </section>
</template>

<style lang="scss">
@import "@/styles/pages/admin-dealer-calculation.scss";
</style>

<script>
import { tableFieldDealerCalculation, articleTypeLabel, articleGroupLabel } from './configs.js';
import DealerCalculationFormDialog from '@/components/DealerCalculationFormDialog';
import DealerCalculationDeleteDialog from '@/components/DealerCalculationDeleteDialog';
import IconCheckPlain from '@/components/icons/IconCheckPlain';
import { mapState } from 'vuex';
export default {
  components: {
    DealerCalculationFormDialog,
    DealerCalculationDeleteDialog,
    IconCheckPlain,
  },
  data () {
    return {
      loading: false,
      calculation: {},
      removeId: 0,
      calculationFormTitle: 'langkey.new-calculation',
    };
  },
  computed: {
    ...mapState({
      calculations: (state) => state.dealerCalculation.calculations,
      calculationData: (state) => state.dealerCalculation.calculationData,
    }),
    tableFieldDealerCalculation() {
      return tableFieldDealerCalculation;
    },
    articleTypeLabel() {
      return articleTypeLabel;
    },
    articleGroupLabel() {
      return articleGroupLabel;
    },
  },
  async created() {
    await this.getCalculations();
  },
  beforeDestroy() {
    this.$store.dispatch('dialog/setLoadingDialog', {
      status: false,
    });
  },
  methods: {
    async openCalculationFormDialog(item = null) {
      this.clearCalculation();
      if (item) {
        this.$store.dispatch('dialog/setLoadingDialog', {
          status: true,
        });
        await this.getCalculationsById(item.id);
        Object.keys(this.calculationData).forEach((key) => {
          this.calculation = {
            ...this.calculation,
            [key]: this.calculationData[key],
          };
        });
        this.$store.dispatch('dialog/setLoadingDialog', {
          status: false,
        });
      }
      this.$refs.dealerCalculationFormDialog.show();
    },
    clearCalculation() {
      this.calculation = {};
    },
    async saveCalculation() {
      this.clearCalculation();
      await this.getCalculations();
    },
    openDeleteCalculationDialog(id) {
      this.removeId = id;
      this.$refs.dealerCalculationDeleteDialog.show();
    },
    async getCalculations() {
      this.loading = true;
      await this.$store.dispatch('dealerCalculation/getCalculations');
      this.loading = false;
    },
    async getCalculationsById(id) {
      await this.$store.dispatch('dealerCalculation/getCalculationById', id);
      this.calculation = this.calculationData;
    },
    async deleteCalculation() {
      this.$store.dispatch('dialog/setLoadingDialog', {
        status: true,
      });
      await this.$store.dispatch('dealerCalculation/deleteCalculation', this.removeId);
      this.removeId = 0;
      await this.getCalculations();
      this.$store.dispatch('dialog/setLoadingDialog', {
        status: false,
      });
    },
    getArticleGroupLabel(articleGroup) {
      if (this.articleGroupLabel[articleGroup.toLowerCase()] &&
        Object.keys(this.articleGroupLabel[articleGroup.toLowerCase()]).length > 0) {
        return this.$t(articleGroupLabel[articleGroup.toLowerCase()].label);
      } else {
        return articleGroup;
      }
    },
  },
};
</script>
