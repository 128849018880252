<template>
  <svg
    id="Component_466_1"
    data-name="Component 466 – 1"
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
  >
    <path
      id="Path_5953"
      data-name="Path 5953"
      d="M9,12l2,2,4-4"
      fill="none"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      default: 24,
    },
    color: {
      type: String,
      default: 'var(--theme-primary)',
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
  },
};
</script>