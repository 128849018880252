var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-menu',{staticClass:"article-type__dropdown",attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"article-type__dropdown-button",class:[
          'dropdown',
          _vm.isShowMenu && 'show-menu'
        ],attrs:{"depressed":"","plain":""},on:{"click":function($event){_vm.isShowMenu = true}}},'v-btn',attrs,false),on),[_c('span',[_vm._v(_vm._s(_vm.getSelectedArticleType))]),_c('div',{class:[
            'icon-caret',
            _vm.isShowMenu && 'open'
          ]},[_c('icon-caret')],1)])]}}]),model:{value:(_vm.isShowMenu),callback:function ($$v) {_vm.isShowMenu=$$v},expression:"isShowMenu"}},[_c('v-list',{staticClass:"article-type-dropdown___content"},_vm._l((_vm.articleTypes),function(articleType,index){return _c('v-list-group',{key:`${index}`,staticClass:"article-group",attrs:{"no-action":"","sub-group":"","value":true,"disabled":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',{staticClass:"aticle-group-title"},[_vm._v(" "+_vm._s(_vm.$t(articleType.label))+" ")])]},proxy:true}],null,true)},_vm._l((articleType.articles),function(article,i){return _c('v-list-item',{key:i},[_c('v-list-item-title',{on:{"click":function($event){return _vm.selectArticleType(article)}}},[_c('span',[_vm._v(_vm._s(_vm.$t(article.label)))])])],1)}),1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }